html {height: 100%;}

img {
	max-width: 100%;
}

// flex to have a sticky footer
body {min-height: 100%; display: flex; flex-direction: column;}
main {flex: 1;}

// PLACEHOLDERS
%margin-0-auto {margin: 0 auto; display: block;}
%wrapper {padding: 0 $bigger-font-size; max-width: $tablet; text-align: left;}
%uppercase {text-transform: uppercase; letter-spacing: 0.2rem; margin-top: $script-font-size;}
%hidden {visibility: hidden;height: 0px;}

// EXTENDS
.wrapper {@extend %wrapper; @extend %margin-0-auto;}
.hidden {@extend %hidden;}

h1 {@extend .header-font;}

.tablet-only{
  display: none;
  @media (min-width: $tablet) {
    display: block;
  }
}

.mobile-only{
  display: block;
  @media (min-width: $tablet) {
    display: none;
  }
}

body {
  color: $text-color;

  // HEADER
  // header.bulky-header {
  //   @extend %margin-0-auto;
  //   margin-top: 1.5*$huge-font-size;
  //   // img {@extend %margin-0-auto;}
  //   // h2 { font-size: $small-font-size; @extend .light-font; @extend %uppercase; text-align: center;}
  //   // .logo-image {width: $huge-font-size*1.3;}
  //   // .main-header-title { width: $huge-font-size*6; margin-top: $regular-font-size;}
  //   @media (min-width: $tablet) {
  //     // .logo-image {width: $huge-font-size*1.8;}
  //     // .main-header-title { width: $huge-font-size*8;}
  //     // h2 { font-size: $regular-font-size;}
  //   }
  //   @media (min-width: $desktop) {
  //     // margin-top: 2.5*$huge-font-size;
  //   }
  // }
  header, footer {
    a {
      border-bottom: 0px;
    }
  }
  header.top-banner {
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    text-align: center;
    height: $top-banner-height;
    color: white;
    background-color: $opaque-red;
    // line-height: $top-banner-height;
    // line-height: 15px;
    z-index: 1000;
    // -webkit-transform: translateZ(0);
  }
  .top-banner-message {
    display: inline-block;
    background: yellow;
    width: 50%;
  }
  .top-banner-spacer {
    height: $top-banner-height*1.2;
  }
  .action-button {
    a {
      border-bottom: 0px;
      color: $opaque-red;
    }
    color: white;
    // background-color: $brand-blue;
    color: $opaque-red;
    background-color: white;
    font-size: $regular-font-size;
    padding: 0.4rem 0.6rem;
    margin: 0 0.4rem;
    font-weight: bold;
    border-radius: 0.6rem;
  }

  header.top-lead {
    // width: 80%;
    // display: flex;
    // justify-content: center; /* align horizontal */
    // align-items: center;
    // height: $header-height;
    // line-height: $header-height;
    padding-left: 1rem;
    margin: $regular-font-size 0;
    @media (min-width: $tablet) {
      margin: $regular-font-size*2 auto;
      max-width: $desktop*1.0;
    }
  }

  // NAVIGATION BAR
  nav.top-nav {
    margin: $regular-font-size/2 auto;
    li.top-nav-elements {
      padding: 0;
      margin: 0 0.2rem;;
      display: inline-block;
      // display: block;
    }
    a {
      display: inline-block;
      margin: $regular-font-size/2 0;
      padding: 2rem 0.6rem;
      font-weight: bold;
      font-size: $small-font-size;
      text-align: center;
      border-radius: 0.0rem;
      color: white;
      border: 0px;
      // border: 2px solid;
      // round
      // padding: 0
      // height: 3rem;
      // width: 3rem;
      // border-radius: 3rem;
      @media (min-width: $tablet) {
        // margin: $mid-font-size;
        font-size: $small-font-size;
        // width: $bigger-font-size*3.0;
      }
      @media (min-width: $desktop) {
        // margin-top: $big-font-size;
        font-size: $regular-font-size;
        // width: $bigger-font-size*3.0;
      }
    }
  }

  // MAIN
  main {

    @extend %margin-0-auto;
    // margin-top: $big-font-size;

    figure {
      img {width: 100%; max-width: $tablet*1.1; @extend %margin-0-auto;}
    }

    header.main-post {
      // @extend %wrapper;
      margin-top: 0;
      h1 {
        margin: $regular-font-size/1 0;
        font-size: 1.2*$bigger-font-size;
	line-height: 1.3;
        @media (min-width: $tablet) {
          font-size: $huge-font-size;
        }
      }
      .author {
        @extend .light-font;
        margin: $regular-font-size/1 0;
        font-size: $small-font-size;
        a {border: 0px; color: $brand-blue;}
        @media (min-width: $tablet) {
          font-size: $regular-font-size;
        }
      }
    }

    .summary {
      margin: $regular-font-size/1 0;
      margin-bottom: $regular-font-size - $paragraph-spacing;
      color: $light-gray;
      font-weight: bold;
      font-size: $mid-font-size;
      line-height: 1.4;
      &.small {font-size: $regular-font-size; margin-top: $script-font-size;}
      @media (min-width: $tablet) {
        font-size: $big-font-size;
        line-height: 1.6;
      }
    }

    article {
      @extend %margin-0-auto;
      @extend %wrapper;
      h2, h3 {
        @extend .header-font;
        font-size: $mid-font-size;
        padding-top: $mid-font-size;
        line-height: 1.4;
        @media (min-width: $tablet) {
          font-size: $big-font-size;
        }
      }
      ul {
        li {
          position: relative;
// background: #88b7d5;
          border-radius: 10px;
          margin: $small-font-size 0;//$huge-font-size;
          padding: 0 $regular-font-size;
          line-height: 1.6;
          // font-size: $regular-font-size*0.9;
        }
        li:nth-child(odd) {
          // text-align: left;
          // margin-left: 0;
          // background-color: #ecf0f1;
        }
        li:nth-child(even) {
          font-style: italic;
          // text-align: right;
          // margin-right: 0;
          // background: $light-gray;
        }
        li:before{
          content: '\2013';
          padding-right: 0.3rem;
          // content: '\00ab';
        }
        li:after{
          // content: '\00bb';
        }
        // li:after{
        //   left: 100%;
        // 	top: 50%;
        // 	border: solid transparent;
        // 	content: " ";
        // 	height: 0;
        // 	width: 0;
        // 	position: absolute;
        // 	pointer-events: none;
        // 	// border-color: rgba(136, 183, 213, 0);
        // 	border-left-color: #ecf0f1;
        // 	border-width: 10px;
        // 	margin-top: -10px;
        // }
      }
      .page-end-logo, .on-page-logo {
        margin: 0 auto;
        padding: $regular-font-size 0 0 0;
        // width: $bigger-font-size;
        width: 100%;
        text-align: center;
        img {
          max-width: 100%;
        }
      }
    }
  } //MAIN close

  // .see-all-posts {
  //   padding-top: $huge-font-size;
  //   padding-bottom: $script-font-size/2;
  //   width:100%;
  //   a {
  //     display: block;
  //     @extend .summary-font;
  //     @extend %margin-0-auto;
  //     @extend %wrapper;
  //     font-size: $big-font-size;
  //     color: $light-gray;
  //     border: 0px;
  //     text-transform: uppercase;
  //     @media (min-width: $tablet) {
  //       font-size: $bigger-font-size;
  //     }
  //   }
  // }

  footer {
    background-color: $opaque-red;
    color: white;
    padding-bottom: $mid-font-size;
    nav.footer-menu {
      padding-top: $mid-font-size;
      padding-bottom: $big-font-size;
      @extend %wrapper;
      a {
        color: white;
        border-bottom: 0px;
        width: 100%;
        font-size: $mid-font-size;
        text-align: left;
        display: block;
        padding: 0;
        margin: 0;
        line-height: 1.8;
        @extend .header-font;
        @media  (min-width: $tablet) {
          font-size: $big-font-size;
        }
      }
    }
    .footer-script-note {
      @extend %wrapper;
      @extend %margin-0-auto;
      font-size: $script-font-size;
      text-align: center;
      line-height: 1.5;
      a {
        color: white;
        border-bottom: 1px solid white;
      }
    }
  } //FOOTER close
} // BODY close



.index {padding-bottom: $huge-font-size; @extend %wrapper;}

.hide-in-mobile {
  display: none !important;
  @media (min-width: $tablet) {
    display: inline-block !important;
  }
}

.catchy-headline {
  display: block;
  @extend .header-font;
  font-size: $mid-font-size;
  padding-top: $mid-font-size;
  line-height: 1.4;
  &.small {font-size: $regular-font-size; padding-top: $regular-font-size;}
  a {border: 0px;}
  @media (min-width: $tablet) {
    font-size: $big-font-size;
    &.small {font-size: $mid-font-size; padding-top: $mid-font-size;}
  }
}

.details {
  margin-top: $script-font-size;
  display: inline-block;
  @extend .light-font;
  font-size: $regular-font-size;
  &.small {font-size: $small-font-size;}
  // &.block { display: block;}
  a {color: $brand-blue; border: 0px;}
  @media (min-width: $tablet) {
    font-size: $mid-font-size;
  }
}

.follow-up {
  // @extend .summary-font;
  text-transform: uppercase;
  font-weight: bold;
  color: $light-gray;
  border: 0px;
  margin-top: $script-font-size;
}

.meaningful-block{
  padding-top: $huge-font-size;
  padding-bottom: $script-font-size/2;
  width:100%;
  a {
    display: block;
    // @extend .summary-font;
    @extend %margin-0-auto;
    @extend %wrapper;
    // font-size: $big-font-size;
    // color: $light-gray;
    // border: 0px;
  }
}

.shift-right {padding-left: $regular-font-size;}
.block {display: block;}
.inline {display: inline-block;}

.mid {
  font-size: $mid-font-size;
  @media (min-width: $tablet) {
    font-size: $big-font-size;
  }
}
.big {
  font-size: $big-font-size;
  @media (min-width: $tablet) {
    font-size: $bigger-font-size;
  }
}
.huge {
  font-size: $huge-font-size;
  &.very {
    font-size: 1.5*$huge-font-size;
    @media (min-width: $tablet) {
      font-size: 2.2*$huge-font-size;
    }
  }
}

.photo-credit {
  padding-top: 0.3rem;
  margin-bottom: -0.6rem;
  font-size: 0.8rem;
  color: $light-gray;
  text-align: right;
}
