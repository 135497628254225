// Very cool color palettes

$dark-black:   #303030;
$light-gray:   #D1D5D8;
$mid-gray:     #666666;
$brand-blue:   #3498DB;
$acid-blue:    #19B5FE;
$midnight-blue:#2c3e50;
$vivid-yellow: #F1C40F;
$opaque-red:   #E74C3C;
$marine-green: #1ABC9C;

$text-color: $midnight-blue;

// Menu colors
.color-1 {background-color: $marine-green;}
.color-2 {background-color: $opaque-red;}
.color-3 {background-color: $vivid-yellow;}
.color-4 {background-color: $brand-blue;}

// .color-1 {color: $marine-green;}
// .color-2 {color: $opaque-red;}
// .color-3 {color: $vivid-yellow;}
// .color-4 {color: $brand-blue;}

a {color: $dark-black;}
a.dark-link {color: $dark-black;}
a.color-link {color: $brand-blue;}

// $dark-black:   #303030;
.light-gray, .light-gray a {color: $light-gray;}
// $mid-gray:     #666666;
// $brand-blue:   #3498DB;
// $vivid-yellow: #F1C40F;
// $opaque-red:   #E74C3C;
// $marine-green: #1ABC9C;
