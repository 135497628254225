a.btn-empty {
    display:inline-block;
    padding:0.5em 3em;
    border:0.16em solid $marine-green;
    margin:0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration:none;
    text-transform:uppercase;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    color: $marine-green;
    text-align:center;
    transition: all 0.15s;
}
a.btn {
    display:inline-block;
    padding:0.5em 3em;
    border:0.16em solid $marine-green;
    // border:0.16em solid $brand-blue;
    margin:0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration:none;
    text-transform:uppercase;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    color: white;
    background-color: $marine-green;
    // background-color: $brand-blue;
    text-align:center;
    transition: all 0.15s;
}
a.btn:hover {
    // color:#DDDDDD;
    border-color:#DDDDDD;
}
a.btn:active {
    color:#BBBBBB;
    border-color:#BBBBBB;
}
@media all and (max-width:30em){
    a.btn {
        display:block;
        margin:0 auto;
    }
}